import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";

import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  petEggWrapper: {
    width: "60%",
    height: "60%",
    transformOrigin: "center center",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    pointerEvents: "none",
  },

  egg0: {
    bottom: "15%",
    left: "3%",
    zIndex: 1,
    transform: "rotate(-30deg)",
    // backgroundImage: props => props.eggImageBefore,
  },
  egg1: {
    bottom: "45%",
    left: "23%",
    zIndex: 0,
    transform: "rotate(0deg)",
    // backgroundImage: props => props.eggImageBefore,
  },
  egg2: {
    bottom: "15%",
    right: "3%",
    zIndex: 1,
    transform: "rotate(20deg)",
    // backgroundImage: props => props.eggImageBefore,
  },

  brokenEgg: {
    // backgroundImage: props => props.eggImageHatching,
    animationName: "broken-egg",
    animationDuration: "0.6s",
  },
  hitArea: {
    width: "60%",
    paddingBottom: "75%",
    marginBottom: "4%",
    borderRadius: "50% 50% 50% 50%",
    overflow: "hidden",
    zIndex: 9,
    position: "absolute",
    pointerEvents: "auto",
  },
});

//For individual pet
const PetViewDesktop = ({ index, locale, data, setBrokenEgg, brokenEgg }) => {
  const {
    id,
    pet_title_variants,
    pet_description_variants,
    egg_image,
    pet_image,
  } = data;

  const classes = useStyles();

  //Handling when mouse is moving
  const handleMouseDown = e => {
    setBrokenEgg(id);

    var imageContainer = document.getElementById("petImgcontainer");
    var titleContainer = document.getElementById("petTitlecontainer");
    var descContainer = document.getElementById("petDesccontainer");

    imageContainer.innerHTML = "";

    var petImage = document.createElement("img");
    petImage.src = extractImagePathUrl(pet_image);
    petImage.style.marginBottom = 0;

    imageContainer.append(petImage);

    titleContainer.innerHTML = getLocaleValue(
      locale,
      pet_title_variants
    ).content;
    descContainer.innerHTML = getLocaleValue(
      locale,
      pet_description_variants
    ).html_content;
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      id={data.id}
      data-title={getLocaleValue(locale, pet_title_variants).content}
      data-desc={getLocaleValue(locale, pet_description_variants).html_content}
      data-pet-image={extractImagePathUrl(pet_image)}
      className={`${classes.petEggWrapper} ${
        index == 0 ? classes.egg0 : index == 1 ? classes.egg1 : classes.egg2
      } ${id == brokenEgg ? classes.brokenEgg : ""}`}
    >
      <Image
        imageObject={id == brokenEgg ? egg_image.hatching : egg_image.before}
      />
      <Box className={classes.hitArea} onMouseDown={handleMouseDown}></Box>
    </Box>
  );
};

export default PetViewDesktop;
