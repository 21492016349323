import React from "react";
import { useSelector } from "react-redux";

import JobsViewDesktop from "./jobs-view-desktop";
import JobsViewTablet from "./jobs-view-tablet";
import JobsViewPhone from "./jobs-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const JobsController = ({
  titleVariants,
  descriptionVariants,
  instructionVariants,
  data,
  location,
  anchorName,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <JobsViewDesktop
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      TabletComponent={
        <JobsViewTablet
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      PhoneComponent={
        <JobsViewPhone
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
    />
  );
};
export default JobsController;
