import React, { useEffect } from "react";

import { Box } from "@material-ui/core";

import JobsController from "../sections/jobs/jobs-controller";
import MapsController from "../sections/maps/maps-controller";
import PetsController from "../sections/pets/pets-controller";
import FashionsController from "../sections/fashions/fashions-controller";

import Header from "../../shared-components/header/header-controller";
import Footer from "../../shared-components/footer/footer-controller";
import BackToTop from "../../shared-components/back-to-top";
import SEO from "../../shared-components/seo";

import { useSelector } from "react-redux";
import { getLocaleValue } from "../../../helpers/get-locale-value";
import { getMetaDetails } from "../../../helpers/get-meta-details";

const GameIntroController = ({ pageContext, location }) => {
  const {
    header,
    footer,
    siteSetting,
    gameIntroPage,
    jobCharacters,
    reloadRequired,
    meta_details_variants,
  } = pageContext;

  const { number_sections } = gameIntroPage;

  let gameIntroSubNavigationSectionAchorName = header.navigations;
  //filter to get the gameintro sub navi
  gameIntroSubNavigationSectionAchorName = gameIntroSubNavigationSectionAchorName.filter(
    a => a.name == "header-navigation-gameintro"
  )[0].sub_navigations;

  //sort navigation by position
  gameIntroSubNavigationSectionAchorName = gameIntroSubNavigationSectionAchorName.sort(
    (a, b) => (a.position > b.position ? 1 : -1)
  );

  useEffect(() => {
    /*
     * If there is a location hash, then do a check on images, and scroll to anchor
     */
    if (location.hash) {
      let counter = 0,
        interval,
        imgs = document.images,
        imgLength = imgs.length;

      /*
       * To force no-auto-scroll on first load
       */
      document.body.scrollTop = 0;

      /*
       * Set Interval to check if there is a change in the img length.
       * This was done as on first load, there is 34 images, but when its fully loaded there is only 20 images.
       * Maybe due to the way the components are mount.
       * So this will be a safer method than a setTimeout as it is a "guessing" method.
       */
      interval = setInterval(function () {
        var temp = document.images;
        if (imgLength == temp.length) {
          clearInterval(interval);
          checkImages();
        } else {
          imgs = document.images;
          imgLength = imgs.length;
        }
      }, 100);

      /*
       * Function to check for all images to be loaded before scrolling
       */
      function checkImages() {
        for (let i of imgs) {
          if (i.complete) {
            incrementCounter(imgs.length);
          } else {
            i.onload = function () {
              incrementCounter(imgs.length);
            };
          }
        }
      }

      /*
       * Function to increase counter when image is loaded
       *
       * @param {integer} imgLength - length of images on document
       */
      function incrementCounter(imgLength) {
        counter++;
        /*
         * When last image is loaded, scroll to section
         */
        if (counter === imgLength) {
          const element = document.getElementById(
            location.hash.replace("#", "")
          );
          if (element) element.scrollIntoView();
        }
      }
    }
  });

  const renderSections = i => {
    const section = gameIntroPage[`section_${i + 1}`];
    const { data_source_type } = section;

    switch (data_source_type) {
      case "job":
        const {
          title_variants: jobTitleVariants,
          description_variants: jobDescriptionVariants,
          instruction_variants: jobInstructionVariants,
          data_source: selectedJobData,
        } = section;

        const combinedJobData = [];

        jobCharacters.map(job => {
          selectedJobData.map(selectedJob => {
            if (job.id === selectedJob.id) {
              combinedJobData.push({
                id: selectedJob.id,
                default_display_gender: selectedJob.default_display_gender,
                job_base_female: selectedJob.job_base_female,
                job_base_male: selectedJob.job_base_male,
                job_detail_female: selectedJob.job_detail_female,
                job_detail_male: selectedJob.job_detail_male,
                job_stats_variants: job.job_stats_variants,
                job_logo: selectedJob.job_logo,
                job_title_variants: job.job_title_variants,
                job_description_variants: job.job_description_variants,
                job_one_liner_variants: job.job_one_liner_variants,
                game_job_class: job.game_job_class,
              });
            }
          });
        });

        return (
          <JobsController
            key={`JobsController` + i}
            anchorName={gameIntroSubNavigationSectionAchorName[i].url.replace(
              "#",
              ""
            )}
            titleVariants={jobTitleVariants}
            descriptionVariants={jobDescriptionVariants}
            instructionVariants={jobInstructionVariants}
            data={combinedJobData}
            location={location}
          />
        );

      case "pet":
        const {
          title_variants: petTitleVariants,
          description_variants: petDescriptionVariants,
          instruction_variants: petInstructionVariants,
          data_source: petData,
        } = section;
        return (
          <PetsController
            key={`PetsController` + i}
            anchorName={gameIntroSubNavigationSectionAchorName[i].url.replace(
              "#",
              ""
            )}
            titleVariants={petTitleVariants}
            descriptionVariants={petDescriptionVariants}
            instructionVariants={petInstructionVariants}
            data={petData}
            location={location}
          />
        );
      case "fashion":
        const {
          title_variants: fashionTitleVariants,
          description_variants: fashionDescriptionVariants,
          data_source: fashionData,
        } = section;
        return (
          <FashionsController
            key={`FashionsController` + i}
            anchorName={gameIntroSubNavigationSectionAchorName[i].url.replace(
              "#",
              ""
            )}
            titleVariants={fashionTitleVariants}
            descriptionVariants={fashionDescriptionVariants}
            data={fashionData}
            location={location}
          />
        );
      case "map":
        const {
          title_variants: mapsTitleVariants,
          description_variants: mapsDescriptionVariants,
          data_source: mapData,
        } = section;
        return (
          <MapsController
            key={`MapsController` + i}
            anchorName={gameIntroSubNavigationSectionAchorName[i].url.replace(
              "#",
              ""
            )}
            titleVariants={mapsTitleVariants}
            descriptionVariants={mapsDescriptionVariants}
            data={mapData}
            location={location}
          />
        );
    }
  };

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const seoData = getMetaDetails(
    locale,
    meta_details_variants,
    siteSetting,
    siteSetting
  );

  return (
    <Box>
      <SEO siteSetting={seoData} />
      <Header
        header={header}
        siteSetting={siteSetting}
        location={location}
        reloadRequired={reloadRequired}
      />
      {Array.from({ length: number_sections }, (_, i) => {
        return renderSections(i);
      })}
      <BackToTop />
      <Footer footer={footer} siteSetting={siteSetting} />
    </Box>
  );
};
export default GameIntroController;
