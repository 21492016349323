import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";

/**
 * Custom trailing arrow animation
 *
 * @param {string} arrowGap - gap between arrows, default 20px
 * @param {string} arrowSize - default 50px
 * @param {string} arrowColor - default white
 * @param {string} direction - default left, can be top | left | right | bottom
 * @param {boolean} isVisible - default true
 */

const useStyles = makeStyles({
  wrapper: {
    width: props => props.arrowSize,
    height: props => props.arrowSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: props => props.directionRotation,
    position: "relative",
    opacity: props => (props.isVisible ? 1 : 0),
  },
  placeHolder: {
    width: "100%;",
    position: "absolute",
    top: 0,
    left: 0,
  },
  arrow: {
    position: "absolute",
    top: 0,
    display: "flex",
    width: props => props.arrowSize,
    height: props => props.arrowSize,
    fontSize: props => props.arrowSize,
  },
  segunda: {
    marginLeft: props => props.arrowGap,
  },
  primera: {
    animationDelay: "0.2s",
  },
  bounceAlpha: {
    transform: "translateX(40px) scale(1)",
    animationName: "$bounceAlpha",
    animationDuration: "1.4s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
  },
  "@keyframes bounceAlpha": {
    "0%": {
      opacity: 1,
      transform: "translateX(40px) scale(1)",
    },
    "25%": {
      opacity: 0,
      transform: "translateX(120px) scale(0.9)",
    },
    "26%": {
      opacity: 1,
      transform: "translateX(0px) scale(0.9)",
    },
    "55%": {
      opacity: 1,
      transform: "translateX(40px) scale(1)",
    },
  },
});

const TrailingArrows = ({
  fontSize,
  arrowSize,
  arrowGap,
  arrowColor,
  direction,
  isVisible,
}) => {
  let directionRotation = "";
  switch (direction) {
    case "top":
      directionRotation = "rotateZ(-90deg)";
      break;
    case "right":
      directionRotation = "rotateZ(180deg)";
      break;
    case "bottom":
      directionRotation = "rotateZ(90deg)";
      break;
    case "left":
    default:
      directionRotation = "rotateZ(0deg)";
  }
  const classes = useStyles({
    fontSize,
    arrowSize,
    arrowGap,
    arrowColor,
    directionRotation,
    isVisible,
  });
  return (
    <div className={classes.wrapper}>
      <div className={classes.placeHolder}>
        <span
          className={`${classes.arrow} ${classes.primera} ${classes.bounceAlpha}`}
        >
          <FiChevronRight />
        </span>
        <span
          className={`${classes.arrow} ${classes.segunda} ${classes.bounceAlpha}`}
        >
          <FiChevronRight />
        </span>
      </div>
    </div>
  );
};
TrailingArrows.defaultProps = {
  arrowSize: "80px",
  arrowGap: "20px",
  arrowColor: "white",
  direction: "left",
  isVisible: true,
};
export default TrailingArrows;
