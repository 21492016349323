import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

//Custom imports images
import PetSectionBg from "../../../../images/game-intro/pets-section-bg.png";
import PetSectionBgDecor from "../../../../images/game-intro/pets-section-bg-decor.png";
import PetSectionNest from "../../../../images/game-intro/pets-section-nest.png";

import PetController from "./pet-controller";

import { Container, Grid, Typography } from "@material-ui/core";
import CustomIconButton from "../../../shared-components/buttons";
import HintBox from "../../../shared-components/hint-box";
// const useStyles = makeStyles({
//   petSectionWrapper: {
//     backgroundImage: `url(${PetSectionBg})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "top center",
//     position: "relative",
//   },

//   petSectionDecor: {
//     backgroundImage: `url(${PetSectionBgDecor})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "top center",
//     position: "absolute",
//     width: "100%",
//     paddingBottom: "100%",
//     bottom: "-30%",
//     zIndex: 1,

//     "@media screen and (max-width: 1120px)": {
//       bottom: "-25%",
//     },

//     "@media screen and (max-width: 980px)": {
//       bottom: "-15%",
//     },
//   },

//   heading: {
//     color: "#70706F",
//   },

//   petContainerWrapper: {
//     position: "relative",
//     zIndex: 1,
//     width: "100%",
//   },
//   petContainer: {
//     maxWidth: 560,
//     minHeight: 400,
//   },

//   PetEggWrapper: {
//     position: "relative",
//     height: 450,
//     width: 450,
//     backgroundImage: "url(" + PetSectionNest + ")",
//     backgroundSize: "contain",
//     backgroundPosition: "bottom center",
//     backgroundRepeat: "no-repeat",
//   },
// });

const useStyles = makeStyles({
  petSectionWrapper: {
    backgroundImage: `url(${PetSectionBg}), linear-gradient(to bottom, transparent 20%, #f6f6d5, #97aa4a, #607029, #789035 )`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "relative",
  },

  petSectionDecor: {
    backgroundImage: `url(${PetSectionBgDecor})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "absolute",
    width: "100%",
    paddingBottom: "100%",
    bottom: "-30%",
    zIndex: 1,

    "@media screen and (max-width: 1120px)": {
      bottom: "-25%",
    },

    "@media screen and (max-width: 980px)": {
      bottom: "-15%",
    },
  },

  heading: {
    color: "#70706F",
  },

  petContainerWrapper: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  petContainer: {
    maxWidth: 560,
    minHeight: 400,
  },

  PetEggWrapper: {
    position: "relative",
    height: 450,
    width: 450,
    backgroundImage: "url(" + PetSectionNest + ")",
    backgroundSize: "contain",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
  },
});

const PetsViewDesktop = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  instructionVariants,
  data,
  anchorName,
}) => {
  const classes = useStyles();

  const maxEggsPerPage = 3;
  const minPage = 0;
  const maxPage = data.length / maxEggsPerPage - 1; //starts from 0
  const [page, setPage] = useState(minPage);
  const [brokenEgg, setBrokenEgg] = useState(-1);

  //To break the data into specific data structures to support pagination
  var eggs = [];
  var currentPage = 0;

  for (let index in data) {
    var numberOfEggs = (index + 1) % maxEggsPerPage;

    if (!eggs[currentPage]) {
      eggs.push([]);
    }

    eggs[currentPage].push(data[index]);

    if (numberOfEggs == 0 && currentPage < maxPage) {
      eggs.push([]);
      currentPage++;
    }
  }

  return (
    <Box
      id={anchorName}
      pt={10}
      pb={3}
      className={classes.petSectionWrapper}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box className={classes.petSectionDecor}></Box>

      <Container maxWidth="md">
        <Box textAlign="center">
          <Typography variant="h3" className={classes.heading}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Typography
            variant="h5"
            className={`${classes.heading} ${classes.subheading}`}
          >
            {getLocaleValue(locale, descriptionVariants).content}
          </Typography>
        </Box>
      </Container>

      <Box
        display="flex"
        justifyContent="center"
        id="pet-container"
        mt={4}
        className={classes.petContainerWrapper}
      >
        <Grid container alignItems="center" className={classes.petContainer}>
          {brokenEgg < 0 ? (
            <Box justifyContent="center" width="100%" display="flex">
              <HintBox
                mt={15}
                text={getLocaleValue(locale, instructionVariants).content}
                isVisible={brokenEgg < 0}
                fontColor="white"
                trailingPosition="bottom"
                trailingArrowsDirection="bottom"
              ></HintBox>
            </Box>
          ) : (
            ""
          )}

          <Grid item sm={5} id="petImgcontainer"></Grid>
          <Grid item sm={7} className={`text-shadow`}>
            <Box
              textAlign="center"
              height="100%"
              fontWeight="bold"
              color="#fff"
            >
              <Typography
                variant="h4"
                id="petTitlecontainer"
                className={`text-secondary`}
              ></Typography>
              <Typography variant="h5" id="petDesccontainer"></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={-5}
        zIndex="2"
      >
        <CustomIconButton
          isCircle="true"
          type="left"
          isDisabled={page > minPage ? false : true}
          onClick={() => {
            if (page > minPage) setPage(page - 1);
          }}
        />
        <Box id="petEggSection" className={classes.PetEggWrapper} mx={1}>
          {eggs[page]?.map((n, i) => (
            <PetController
              key={i}
              data={n}
              index={i}
              setBrokenEgg={setBrokenEgg}
              brokenEgg={brokenEgg}
            />
          ))}
        </Box>
        <CustomIconButton
          isCircle="true"
          type="right"
          isDisabled={page < maxPage ? false : true}
          onClick={() => {
            if (page < maxPage) setPage(page + 1);
          }}
        />
      </Box>
    </Box>
  );
};

export default PetsViewDesktop;
