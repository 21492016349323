import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import cloudsBg from "../../../../images/game-intro/maps-section-cloud-bg.png";
import cloud1 from "../../../../images/game-intro/maps-section-cloud-1.png";
import cloud2 from "../../../../images/game-intro/maps-section-cloud-2.png";
import cloud3 from "../../../../images/game-intro/maps-section-cloud-3.png";
import cloud4 from "../../../../images/game-intro/maps-section-cloud-4.png";

import MapItemController from "./map-item-controller";

import { Container, Typography } from "@material-ui/core";
import Img from "gatsby-image";
import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  sectionWrapper: {
    background: "linear-gradient(0deg, #fafafa, #1D70B7)",
    position: "relative",
  },
  heading: {
    color: "#fff",
  },
  cloudsBgWrapper: {
    backgroundImage: `url(${cloudsBg})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    animation: "bouncing-clouds 6s infinite ease-in-out",
    marginTop: "5%",
    "@media screen and (max-width: 1400px)": {
      marginTop: "7%",
    },
  },

  cloudWrapper: {
    position: "absolute",
    top: "-20%",
    width: "100%",
    paddingBottom: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    zIndex: 1,
  },

  cloud1: {
    backgroundImage: `url(${cloud1})`,
    top: "-15%",
    animation: "bouncing-clouds 2s infinite ease-in-out",
  },
  cloud2: {
    backgroundImage: `url(${cloud2})`,
    top: "-10%",
    animation: "bouncing-clouds 3s infinite ease-in-out",
  },
  cloud3: {
    backgroundImage: `url(${cloud3})`,
    animation: "bouncing-clouds 4s infinite ease-in-out",
  },
  cloud4: {
    backgroundImage: `url(${cloud4})`,
    animation: "bouncing-clouds 6s infinite ease-in-out",
  },
});

const MapsViewDesktop = ({
  locale,
  location,
  data,
  titleVariants,
  descriptionVariants,
  anchorName,
}) => {
  const classes = useStyles();

  const [selectedBuilding, setSelectedBuilding] = useState("");
  const getMapMeasurements = data => {
    switch (data) {
      case "map-midgard":
        return {
          width: "75%",
          marginTop: 0,
          marginRight: 0,
          marginLeft: { lg: "5%", xl: "8%" },
          justifyContent: "flex-start",
        };

      case "map-unknownfield":
        return {
          width: "70%",
          marginTop: "-5%",
          marginRight: "-11%",
          marginLeft: { lg: "-8%", xl: "-5%" },
          justifyContent: "flex-end",
        };
      default:
        return {
          width: "75%",
          marginTop: 0,
          marginRight: 0,
          marginLeft: { lg: "5%", xl: "8%" },
          justifyContent: "flex-start",
        };
    }
  };

  return (
    <Box
      id={anchorName}
      pt={20}
      className={classes.sectionWrapper}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box
        position="absolute"
        width="100%"
        pb="100%"
        className={classes.cloudsBgWrapper}
      ></Box>

      <Container maxWidth="lg">
        <Box textAlign="center">
          <Typography variant="h2" className={classes.heading}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Typography variant="h4" className={classes.heading}>
            {getLocaleValue(locale, descriptionVariants).content}
          </Typography>
        </Box>
      </Container>

      <Box
        position="relative"
        width="100%"
        mt={{ lg: 15, xl: 5 }}
        py={{ lg: 0, xl: 10 }}
      >
        <Box className={`${classes.cloudWrapper} ${classes.cloud1}`}></Box>
        <Box className={`${classes.cloudWrapper} ${classes.cloud2}`}></Box>
        <Box className={`${classes.cloudWrapper} ${classes.cloud3}`}></Box>
        <Box className={`${classes.cloudWrapper} ${classes.cloud4}`}></Box>

        {data &&
          data.map((n, i) => (
            <Box
              key={`Map` + n.name + i}
              display="flex"
              width="100%"
              py={3}
              justifyContent={getMapMeasurements(n.name).justifyContent}
            >
              <Box
                width={getMapMeasurements(n.name).width}
                ml={getMapMeasurements(n.name).marginLeft}
                mt={getMapMeasurements(n.name).marginTop}
                mr={getMapMeasurements(n.name).marginRight}
                position="relative"
              >
                <Image loading="eager" imageObject={n.map_image} width="100%" />
                {n.towns &&
                  n.towns.map((m, j) => (
                    <MapItemController
                      key={`MapItemController` + n.name + j}
                      index={j}
                      selectedBuilding={selectedBuilding}
                      setSelectedBuilding={setSelectedBuilding}
                      data={m}
                      building={m.name}
                    />
                  ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default MapsViewDesktop;
