import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FashionsViewDesktop from "./fashions-view-desktop";
import FashionsViewTablet from "./fashions-view-tablet";
import FashionsViewPhone from "./fashions-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";
import { setImageWhenOnload } from "../../../shared-components/image/image-onload";

const FashionsController = ({
  titleVariants,
  descriptionVariants,
  data,
  location,
  anchorName,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  useEffect(() => {
    for (const item of data) {
      setImageWhenOnload(item.female_fashion_image, null);
      setImageWhenOnload(item.male_fashion_image, null);
    }
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <FashionsViewDesktop
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      TabletComponent={
        <FashionsViewTablet
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      PhoneComponent={
        <FashionsViewPhone
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
    />
  );
};
export default FashionsController;
