import React from "react";
import { useSelector } from "react-redux";

import PetViewDesktop from "./pet-view-desktop";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const PetController = ({ index, data, setBrokenEgg, brokenEgg }) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <PetViewDesktop
          locale={locale}
          data={data}
          index={index}
          setBrokenEgg={setBrokenEgg}
          brokenEgg={brokenEgg}
        />
      }
      TabletComponent={
        <PetViewDesktop
          locale={locale}
          data={data}
          index={index}
          setBrokenEgg={setBrokenEgg}
          brokenEgg={brokenEgg}
        />
      }
      PhoneComponent={
        <PetViewDesktop
          locale={locale}
          data={data}
          index={index}
          setBrokenEgg={setBrokenEgg}
          brokenEgg={brokenEgg}
        />
      }
    />
  );
};
export default PetController;
