import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Parser from "html-react-parser";
import Popup from "reactjs-popup";

import CustomIconButton from "../../../shared-components/buttons";
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";
import { getLocaleValue } from "../../../../helpers/get-locale-value";

import skybg from "../../../../images/game-intro/jobs-section-sky-bg.jpg";
import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  carouselCell: props => ({
    width: "33%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    overflow: "visible",
  }),
  cellCharacter: props => ({
    width: "100%",
    transform: `scale(${props.childScale})`,
    filter: `brightness(${props.childBrightnessReduction}%)`,
    transition: "transform 800ms, filter 1s ease-in",
  }),
  current: {
    cursor: "pointer",
  },
  popupContainer: {
    backgroundImage: `url(${skybg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    width: "65vw",
    position: "relative",
    borderRadius: "30px",
    padding: "50px",
    boxShadow: "5px 5px 10px rgba(50, 50, 50, 0.8) !important",
  },
  jobTitle: {
    color: "#FF8923",
  },
  jobStats: {
    width: "75%",
    margin: "1em 0",
  },
  jobDescription: {
    textAlign: "center",
  },
  detailJobImage: {
    width: "100%",
    opacity: 1,
    transition: "opacity 800ms ease-in-out",
  },
  detailAltJobImage: {
    position: "absolute!important",
    width: "100%",
    top: 0,
    left: 0,
    opacity: 1,
    transition: "opacity 800ms ease-in-out",
  },
  transparent: {
    opacity: 0,
  },
  jobLogo: {
    width: "60px",
    marginBottom: 0,
  },
  box: {
    transformStyle: "preserve-3d",
  },
});

const JobViewDesktop = ({
  index,
  selectedIndex,
  totalCount,
  locale,
  data,
  setIsViewedDetail,
}) => {
  let childScale = 0.7;
  let childGrayscaleTopup = 1;
  let childBrightnessReduction = 50;

  if (index == selectedIndex) {
    childScale = 1.0;
    childGrayscaleTopup = 0;
    childBrightnessReduction = 100;
  }

  const {
    job_title_variants,
    job_one_liner_variants,
    job_description_variants,
    job_stats_variants,
    job_logo,
    job_base_female,
    job_base_male,
    job_detail_female,
    job_detail_male,
    default_display_gender,
  } = data;

  const [showDetail, setShowDetail] = useState(false);
  const [displayGender, setDisplayGender] = useState(default_display_gender);
  const has2Genders = job_base_female && job_base_male;

  const classes = useStyles({
    childScale,
    childGrayscaleTopup,
    childBrightnessReduction,
  });

  return (
    <Box
      id={`job-character-${index}`}
      className={`${classes.carouselCell} ${
        index == selectedIndex ? classes.current : ""
      }`}
      onClick={() => {
        index == selectedIndex && setShowDetail(!showDetail);
        setIsViewedDetail(true);
      }}
    >
      <Image
        className={classes.cellCharacter}
        loading="eager"
        imageObject={
          default_display_gender === "female"
            ? job_base_female
              ? job_base_female
              : ""
            : job_base_male
            ? job_base_male
            : ""
        }
      />
      {index == selectedIndex && (
        <Popup
          open={showDetail}
          closeOnDocumentClick
          overlayStyle={{ backgroundColor: "rgba(0,0,0,.5)" }}
          contentStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClose={() => {
            setShowDetail(false);
          }}
        >
          <Box
            display="flex"
            direction="row"
            className={classes.popupContainer}
          >
            <IoCloseOutline
              size="2.5em"
              style={{
                color: "#FF8923",
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowDetail(false);
              }}
            />
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Box position="relative" width="100%" maxWidth="500px">
                    {job_detail_female && (
                      <Image
                        className={`${classes.detailJobImage} ${
                          has2Genders && displayGender === "male"
                            ? classes.transparent
                            : ""
                        }`}
                        imageObject={job_detail_female}
                      />
                    )}
                    {job_detail_male && (
                      <Image
                        className={`${
                          has2Genders
                            ? classes.detailAltJobImage
                            : classes.detailJobImage
                        } ${
                          has2Genders && displayGender === "female"
                            ? classes.transparent
                            : ""
                        }`}
                        imageObject={job_detail_male}
                      />
                    )}
                  </Box>

                  <Box display="flex" alignItems="center" mt={2}>
                    {job_detail_male && (
                      <CustomIconButton
                        mx={1}
                        type="male"
                        bgColor="#199cd9"
                        isCircle={true}
                        isInactive={
                          has2Genders ? displayGender != "male" : false
                        }
                        onClick={() => setDisplayGender("male")}
                      />
                    )}
                    {job_detail_female && (
                      <CustomIconButton
                        mx={1}
                        type="female"
                        bgColor="#e40d7e"
                        isCircle={true}
                        isInactive={
                          has2Genders ? displayGender != "female" : false
                        }
                        onClick={() => setDisplayGender("female")}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    className={classes.jobLogo}
                    loading={"lazy"}
                    imageObject={job_logo}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h4" className={classes.jobTitle}>
                      {getLocaleValue(locale, job_title_variants).content}
                    </Typography>
                    <Typography variant="h6">
                      {getLocaleValue(locale, job_one_liner_variants).content}
                    </Typography>
                  </Box>

                  <Image
                    className={classes.jobStats}
                    loading={"lazy"}
                    imageObject={
                      getLocaleValue(locale, job_stats_variants).image
                    }
                  />
                  <Box className={classes.jobDescription}>
                    {Parser(
                      getLocaleValue(locale, job_description_variants)
                        .html_content
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Popup>
      )}
    </Box>
  );
};

export default JobViewDesktop;
