import React, { useEffect, useState } from "react";
import { Grid, Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Flickity from "react-flickity-component";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import JobController from "./job-controller";

import StarryBg from "../../../shared-components/starry-bg";

// Cloud images
import clouds from "../../../../images/game-intro/jobs-section-clouds.png";
import cloud1 from "../../../../images/game-intro/cloud-1.png";
import cloud2 from "../../../../images/game-intro/cloud-2.png";
import cloud3 from "../../../../images/game-intro/cloud-3.png";
import cloud4 from "../../../../images/game-intro/cloud-4.png";

import "flickity/css/flickity.css";

const useStyles = makeStyles({
  sectionJobs: {
    background: "linear-gradient(0deg, #1D70B7, #5CC0E1, #152D60)",
    position: "relative",
    zIndex: 1,

    "&::before": {
      content: '""',
      backgroundImage: `url(${clouds})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "bottom center",
      display: "block",
      width: "100%",
      paddingBottom: "60%",
      position: "absolute",
      bottom: "-10%",
      zIndex: 0,
      animationDuration: "4s",
      animationName: "bouncing-clouds-2",
      animationIterationCount: "infinite",
      animationTimingFunction: "ease-in-out",

      "@media screen and (max-width: 1600px)": {
        backgroundSize: "auto",
      },
    },
  },
  title: {
    color: "#ffffff",
    marginBottom: "10px",
  },
  description: {
    color: "#ffffff",
  },
  flickityOverwrite: {
    "& .flickity-prev-next-button.next": {
      right: "20px !important",
    },
    "& .flickity-prev-next-button.previous": {
      left: "20px !important",
    },
  },
  starrybg: {
    position: "absolute",
    top: 0,
    left: "-40%",
    width: "140%",
    height: "100%",
    zIndex: 0,
  },
  cloud: {
    width: "30%",
    paddingBottom: "25%",
    bottom: 0,
    position: "absolute",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    animationName: "bouncing-clouds-2",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    zIndex: 1,
  },
  cloud1: {
    backgroundImage: `url(${cloud1})`,
    left: "50%",
    bottom: "-10%",
    animationDuration: "2s",
  },
  cloud2: {
    backgroundImage: `url(${cloud2})`,
    left: "10%",
    bottom: "-8%",
    animationDelay: "1s",
    animationDuration: "3.5s",
  },
  cloud3: {
    backgroundImage: `url(${cloud3})`,
    right: "0",
    animationDuration: "2.2s",
  },
  cloud4: {
    backgroundImage: `url(${cloud4})`,
    left: "-10%",
    bottom: "-5%",
    animationDuration: "2.5s",
  },
});

const JobsViewPhone = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  data,
  anchorName,
}) => {
  const classes = useStyles();

  // Can check the data object structure example at the bottom
  return (
    <Box
      id={anchorName}
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.sectionJobs}
      pt="30px"
    >
      <Box className={classes.starrybg}>
        <StarryBg />
      </Box>
      <Box className={`${classes.cloud} ${classes.cloud1}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud2}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud3}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud4}`}></Box>

      <Container maxWidth="md">
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" className={classes.title}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Box textAlign="center" mt={1}>
            <Typography variant="h6" className={classes.title}>
              {getLocaleValue(locale, descriptionVariants).content}
            </Typography>
          </Box>
        </Box>
      </Container>

      <Grid container>
        <Grid item xs={12}>
          <Flickity
            className={`carousel gallery ${classes.flickityOverwrite}`} // default ''
            elementType={"div"} // default 'div'
            options={{
              wrapAround: false,
              pageDots: false,
              initialIndex: 0,
              prevNextButtons: true,
              contain: true,
            }} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {data &&
              data.map((n, i) => (
                <JobController key={i} data={n} isInView={false} />
              ))}
          </Flickity>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobsViewPhone;
