import React from "react";
import { useSelector } from "react-redux";

import JobViewDesktop from "./job-view-desktop";
import JobViewTablet from "./job-view-tablet";
import JobViewPhone from "./job-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";
import { keyframes } from "styled-components";

const JobController = ({
  index,
  selectedIndex,
  totalCount,
  carouselCellSize,
  isInView,
  data,
  setIsViewedDetail,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      isPreserve3D={true}
      DesktopComponent={
        <JobViewDesktop
          index={index}
          selectedIndex={selectedIndex}
          totalCount={totalCount}
          carouselCellSize={carouselCellSize}
          locale={locale}
          data={data}
          setIsViewedDetail={setIsViewedDetail}
        />
      }
      TabletComponent={
        <JobViewTablet locale={locale} isInView={isInView} data={data} />
      }
      PhoneComponent={
        <JobViewPhone locale={locale} isInView={isInView} data={data} />
      }
    />
  );
};

JobController.defaultProps = {
  carouselCellSize: {
    width: 800,
    height: 800,
  },
  index: 0,
  selectedIndex: 0,
  totalCount: 0,
  isInView: false,
  data: {},
};

export default JobController;
