import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

/**
 * Custom star animation background
 */
const starryBg = () => {
  const useStyles = makeStyles({
    stars: {
      //   transform: "scale(1.5)",
      width: "100%",
      height: "100%",
      position: "relative",
      transformOrigin: "top",
    },
    star: {
      display: "block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#FFF",
      position: "relative",
      transformOrigin: "100% 0",
      boxShadow: "0 0 10px 10px rgba(255, 255, 255, .5)",
      opacity: 0,
      animation: "star-ani 3s infinite ease-out",

      "&:after": {
        content: '""',
        display: "block",
        border: "0px solid",
        borderWidth: "5px 200px 5px 200px",
        borderColor:
          "transparent transparent transparent rgba(255, 255, 255, 1)",
        transform: "rotate(-40deg) translate(8px, 4px)",
        transformOrigin: "0% 100%",
        boxShadow: "0 0 1px 0 rgba(255, 255, 255, .1)",
        // animation: "shooting-ani 1.5s infinite ease-out",
      },
    },
    starBlue: {
      boxShadow: "0 0 10px 10px rgba(0, 244, 255, .5)",
      background: "rgba(0, 244, 255, 1)",

      "&:after": {
        borderColor:
          "transparent transparent transparent rgba(0, 244, 255, .5)",
        boxShadow: "0 0 1px 0 rgba(0, 244, 255, .1)",
      },
    },

    starPurple: {
      boxShadow: "0 0 10px 10px rgba(217, 156, 255, .5)",
      background: "rgba(217, 156, 255, 1)",

      "&:after": {
        borderColor:
          "transparent transparent transparent rgba(217, 156, 255, .5)",
        boxShadow: "0 0 1px 0 rgba(217, 156, 255, .1)",
      },
    },
    star1: {
      top: "0%",
      left: "15%",
      animationDelay: "1s",
    },
    star2: {
      top: "0%",
      left: "50%",
      animationDuration: "2s",
    },
    star3: {
      top: "0%",
      left: "100%",
      animationDelay: "2s",
    },
    star4: {
      top: "10%",
      left: "20%",
      animationDuration: "1s",
    },
    star5: {
      top: "10%",
      left: "60%",
      animationDelay: "0.8s",
    },
    star6: {
      top: "10%",
      left: "80%",
      animationDuration: "1.5s",
    },
    star7: {
      top: "20%",
      left: "10%",
      animationDelay: "1.2s",
    },
    star8: {
      top: "20%",
      left: "50%",
      animationDuration: "4s",
    },
    star9: {
      top: "20%",
      left: "100%",
      animationDelay: "0.5s",
    },
    star10: {
      top: "30%",
      left: "25%",
      animationDelay: "2s",
    },
    star11: {
      top: "30%",
      left: "50%",
      animationDuration: "4s",
    },
    star12: {
      top: "30%",
      left: "90%",
      animationDuration: "1.5s",
    },
    star13: {
      top: "40%",
      left: "15%",
      animationDelay: "0.5s",
    },
    star14: {
      top: "40%",
      left: "50%",
      animationDuration: "1.5s",
    },
    star15: {
      top: "40%",
      left: "100%",
      animationDelay: "1.2s",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.stars}>
      <Box className={`${classes.star} ${classes.star1}`}></Box>
      <Box className={`${classes.star} ${classes.star2}`}></Box>
      <Box className={`${classes.star} ${classes.star3}`}></Box>
      <Box className={`${classes.star} ${classes.star4}`}></Box>
      <Box className={`${classes.star} ${classes.star5}`}></Box>
      <Box className={`${classes.star} ${classes.star6}`}></Box>
      <Box className={`${classes.star} ${classes.star7}`}></Box>
      <Box className={`${classes.star} ${classes.star8}`}></Box>
      <Box className={`${classes.star} ${classes.star9}`}></Box>
      <Box className={`${classes.star} ${classes.star10}`}></Box>
      <Box className={`${classes.star} ${classes.star11}`}></Box>
      <Box className={`${classes.star} ${classes.star12}`}></Box>
      <Box className={`${classes.star} ${classes.star13}`}></Box>
      <Box className={`${classes.star} ${classes.star14}`}></Box>
      <Box className={`${classes.star} ${classes.star15}`}></Box>
      <Box
        className={`${classes.star} ${classes.starBlue} ${classes.star10}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starBlue} ${classes.star9}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starBlue} ${classes.star6}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starBlue} ${classes.star7}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starBlue} ${classes.star8}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starPurple} ${classes.star1}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starPurple} ${classes.star2}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starPurple} ${classes.star3}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starPurple} ${classes.star4}`}
      ></Box>
      <Box
        className={`${classes.star} ${classes.starPurple} ${classes.star5}`}
      ></Box>
    </Box>
  );
};
export default starryBg;
