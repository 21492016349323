import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import TrailingArrows from "../trailing-arrows";

/**
 * Custom Hint Box Component with animation
 *
 * @param {boolean} isVisible - to determine if hint box is visible, false by default
 * @param {string} text - the text to be shown in the box
 * @param {string} width - width of hint box, can be given in terms of string "40px" or number, auto by default
 * @param {string} height - height of hint box, can be given in terms of string "40px" or number, auto by default
 * @param {string} bgColor - the background color of hint box, white by default
 * @param {string} fontColor - the text color of box, black by default
 * @param {number} fontSize - font size of box within the hint box, 2em by default
 * @param {number} borderRadius - border radius of box, 10 by default
 * @param {number} px - Horizontal padding values of hint box, {3} by default
 * @param {number} py - Vertical padding values of hint box, {1.5} by default
 * @param {number} mt - Top margin values of hint box, 0 by default
 * @param {number} mb - Bottom margin values of hint box, 0 by default
 * @param {string} trailingPosition - position of the trailing of the text, default top
 * @param {string} trailingArrowsArrowGap - gap between arrows, default 20px
 * @param {string} trailingArrowsArrowSize - default 50px
 * @param {string} trailingArrowsArrowColor - default white
 * @param {string} trailingArrowsDirection - default left, can be top | left | right | bottom
 * @param {boolean} trailingArrowsIsVisible - default true
 */
const hintBox = ({
  isVisible,
  text,
  width,
  height,
  bgColor,
  fontColor,
  fontSize,
  borderRadius,
  px,
  py,
  mt,
  mb,
  trailingPosition,
  trailingArrowsArrowSize,
  trailingArrowsArrowGap,
  trailingArrowsArrowColor,
  trailingArrowsDirection,
  trailingArrowsIsVisible,
}) => {
  const useStyles = makeStyles({
    box: {
      border: 0,
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      animation: "hint-box 1s infinite ease-in",
      display: isVisible ? "flex" : "none",
      flexDirection: "column",
      backgroundColor: bgColor,
      height: height,
      width: width,
      fontSize: fontSize,
      color: fontColor,
      borderRadius: borderRadius,
      textShadow: "1px 1px 5px #70706f",
      textTransform: "uppercase",
      letterSpacing: "3px",
    },
  });

  const classes = useStyles();

  return (
    <Box px={px} py={py} mt={mt} mb={mb} className={`${classes.box}`}>
      {trailingPosition == "top" ? (
        <TrailingArrows
          className={`${classes.trailing}`}
          arrowSize={trailingArrowsArrowSize}
          arrowGap={trailingArrowsArrowGap}
          arrowColor={trailingArrowsArrowColor}
          direction={trailingArrowsDirection}
          isVisible={trailingArrowsIsVisible}
        />
      ) : (
        ""
      )}
      {text}{" "}
      {trailingPosition == "bottom" ? (
        <TrailingArrows
          className={`${classes.trailing}`}
          arrowSize={trailingArrowsArrowSize}
          arrowGap={trailingArrowsArrowGap}
          arrowColor={trailingArrowsArrowColor}
          direction={trailingArrowsDirection}
          isVisible={trailingArrowsIsVisible}
        />
      ) : (
        ""
      )}
    </Box>
  );
};
hintBox.defaultProps = {
  isVisible: true,
  text: "Hint box",
  width: "auto",
  height: "auto",
  fontColor: "black",
  borderRadius: 10,
  fontSize: "2em",
  px: 3,
  py: 1.5,
  mb: 0,
  mt: 0,
  trailingPosition: "top",
};
export default hintBox;
