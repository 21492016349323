import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Parser from "html-react-parser";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import CustomIconButton from "../../../shared-components/buttons";

import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";
import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  job: {
    position: "relative",
    width: props => props.cardSize.width,
    minHeight: props => props.cardSize.height,
    maxHeight: props => props.cardSize.height,
    overflowX: "hidden",
  },
  backdropImage: props => ({
    position: "absolute",
    width: props.cardSize.width,
    minHeight: props.cardSize.height,
    maxHeight: props.cardSize.height,
    top: 0,
    right: 0,
    backgroundImage: `url(${
      props.selectedDisplayGender === "female"
        ? props.job_detail_female
          ? extractImagePathUrl(props.job_detail_female)
          : ""
        : props.job_detail_male
        ? extractImagePathUrl(props.job_detail_male)
        : ""
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right -200px top -6px",
    backgroundSize: "800px 800px",
  }),
  "@keyframes fadeInTranslateRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(60%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  "@keyframes fadeInTranslateLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(-60%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: props => props.cardSize.width,
    minHeight: props => props.cardSize.height,
    maxHeight: props => props.cardSize.height,
    // backgroundColor: "rgba(0,0,0,0.12)",
  },
  jobTitle: {
    color: "#FF8923",
  },
  jobOneLiner: {
    color: "#FFFFFF",
    whiteSpace: "nowrap",
  },
  jobStats: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    width: "45%",
    zIndex: 999,
    marginLeft: "8%",
    marginTop: "-10%",
    padding: "15px",
    borderRadius: "20px",
  },
  jobDescription: {
    textAlign: "center",
    color: "#FFFFFF",
    zIndex: 999,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: "20px",
  },
  jobLogo: {
    maxWidth: "80px",
    marginBottom: 0,
  },
  genderSign: {
    backgroundColor: "rgba(255,255,255,0.75)",
    position: "absolute!important",
    top: "10%",
    right: "0px",
    padding: "10px",
    borderRadius: "15px 0px 0px 15px",
    display: "flex",
  },
});

const JobViewTablet = ({ locale, data, isInView }) => {
  const {
    job_title_variants,
    job_one_liner_variants,
    job_description_variants,
    job_base_female,
    job_base_male,
    job_detail_female,
    job_detail_male,
    job_stats_variants,
    job_logo,
    default_display_gender,
  } = data;

  const [selectedDisplayGender, setSelectedDisplayGender] = useState(
    default_display_gender
  );

  const cardSize = { width: "100%", height: "800px" };
  const useStyles = makeStyles({
    job: {
      position: "relative",
      width: cardSize.width,
      minHeight: cardSize.height,
      maxHeight: cardSize.height,
      overflowX: "hidden",
    },
    backdropImage: {
      position: "absolute",
      width: cardSize.width,
      minHeight: cardSize.height,
      maxHeight: cardSize.height,
      top: 0,
      right: 0,
      backgroundImage: `url(${
        selectedDisplayGender === "female"
          ? job_detail_female
            ? extractImagePathUrl(job_detail_female)
            : ""
          : job_detail_male
          ? extractImagePathUrl(job_detail_male)
          : ""
      })`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right -200px top -6px",
      backgroundSize: "800px 800px",
    },
    "@keyframes fadeInTranslateRight": {
      "0%": {
        opacity: 0,
        transform: "translateX(60%)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
    "@keyframes fadeInTranslateLeft": {
      "0%": {
        opacity: 0,
        transform: "translateX(-60%)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
    backdrop: {
      position: "absolute",
      top: 0,
      left: 0,
      width: cardSize.width,
      minHeight: cardSize.height,
      maxHeight: cardSize.height,
      // backgroundColor: "rgba(0,0,0,0.12)",
    },
    jobTitle: {
      color: "#FF8923",
    },
    jobOneLiner: {
      color: "#FFFFFF",
      whiteSpace: "nowrap",
    },
    jobStats: {
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      width: "45%",
      zIndex: 999,
      marginLeft: "8%",
      marginTop: "-10%",
      padding: "15px",
      borderRadius: "20px",
    },
    jobDescription: {
      textAlign: "center",
      color: "#FFFFFF",
      zIndex: 999,
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.7)",
      padding: "20px",
    },
    jobLogo: {
      maxWidth: "80px",
      marginBottom: 0,
    },
    genderSign: {
      backgroundColor: "rgba(255,255,255,0.75)",
      position: "absolute!important",
      top: "10%",
      right: "0px",
      padding: "10px",
      borderRadius: "15px 0px 0px 15px",
      display: "flex",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.job}>
      <Box
        flex="1"
        display="flex"
        height={cardSize.height}
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box className={classes.backdropImage} />
        <Box className={classes.backdrop} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          justifyContent="space-between"
          zIndex="999"
        >
          <Box display="flex" alignItems="center" px="30px">
            <Image className={classes.jobLogo} imageObject={job_logo} />
            <Box ml={2}>
              <Typography
                variant="h4"
                className={`${classes.jobTitle} text-shadow`}
              >
                {getLocaleValue(locale, job_title_variants).content}
              </Typography>
              <Typography variant="h6" className={classes.jobOneLiner}>
                {getLocaleValue(locale, job_one_liner_variants).content}
              </Typography>
            </Box>
          </Box>

          <Image
            className={classes.jobStats}
            style={{ marginBottom: "0px" }}
            imageObject={getLocaleValue(locale, job_stats_variants).image}
          />
          {job_base_female && job_base_male ? (
            <Box className={classes.genderSign}>
              <CustomIconButton
                mx={1}
                type="female"
                bgColor="#e40d7e"
                isCircle={true}
                fontSize="2.5em"
                width="50px"
                height="50px"
                isInactive={selectedDisplayGender != "female"}
                onClick={() => setSelectedDisplayGender("female")}
              />
              <CustomIconButton
                mx={1}
                type="male"
                bgColor="#199cd9"
                fontSize="2.5em"
                width="50px"
                height="50px"
                isCircle={true}
                isInactive={selectedDisplayGender != "male"}
                onClick={() => setSelectedDisplayGender("male")}
              />
            </Box>
          ) : (
            ""
          )}
          <Typography variant="h6" className={classes.jobDescription}>
            {Parser(
              getLocaleValue(locale, job_description_variants).html_content
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default JobViewTablet;
