import React from "react";
import { useSelector } from "react-redux";

import FashionViewDesktop from "./fashion-view-desktop";
import FashionViewTablet from "./fashion-view-tablet";
import FashionViewPhone from "./fashion-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const FashionController = ({
  index,
  data,
  type,
  gender,
  selected,
  setSelectedModel,
  setGenderModel,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <FashionViewDesktop
          index={index}
          locale={locale}
          type={type}
          gender={gender}
          data={data}
          selected={selected}
          setSelectedModel={setSelectedModel}
        />
      }
      TabletComponent={
        <FashionViewTablet
          index={index}
          locale={locale}
          type={type}
          gender={gender}
          data={data}
          selected={selected}
          setSelectedModel={setSelectedModel}
          setGenderModel={setGenderModel}
        />
      }
      PhoneComponent={
        <FashionViewPhone
          index={index}
          locale={locale}
          type={type}
          gender={gender}
          data={data}
          selected={selected}
          setSelectedModel={setSelectedModel}
          setGenderModel={setGenderModel}
        />
      }
    />
  );
};
export default FashionController;
