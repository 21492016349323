import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import { Typography } from "@material-ui/core";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";

const useStyles = makeStyles({
  buildingWrapper: {
    position: "absolute",
    zIndex: 2,
    top: props => props.coordinates.y,
    left: props => props.coordinates.x,
    animation: "bouncing-building infinite ease-in-out",
    animationDuration: props => props.index * 0.5 + 1.2 + "s",
  },
  building: {
    width: "150px",
    height: "150px",
    backgroundImage: props => props.townImage,
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",

    "@media screen and (max-width: 1600px)": {
      width: "120px",
      height: "120px",
    },
  },

  title: {
    backgroundColor: "#F1872C",
    fontWeight: "bold",
    color: "white",
    borderRadius: "20px",
    boxShadow: "2px 2px 5px rgba(0,0,0,0.8)!important",
    fontSize: "85%",
    whiteSpace: "nowrap",
    minWidth: "165px",
  },

  description: {
    position: "absolute",
    color: "white",
    background: "rgba(0, 0, 0, 0.8)",
    borderRadius: "20px",
    boxShadow: "2px 2px 5px rgba(0,0,0,0.8)!important",
    width: "180%",
    zIndex: -1,
    bottom: 0,
    opacity: 0,

    "@media screen and (max-width: 1600px)": {
      width: "170%",
    },
  },

  showBuildingDesc: {
    transition: "all 0.4s ease-in",
    opacity: 1,

    "&.left": {
      left: 0,
      transform: `translateX(-105%)`,
    },
    "&.right": {
      right: 0,
      transform: `translateX(105%)`,
    },
  },
  comingsoon: {
    position: "absolute",
    right: "30%",
    width: "auto",
    top: "50%",
    zIndex: 2,

    "@media screen and (max-width: 1600px)": {
      top: "45%",
    },
  },
});

const MapItemDesktop = ({
  index,
  locale,
  data,
  selectedBuilding,
  setSelectedBuilding,
}) => {
  let coordinates = { x: "0%", y: "0%" }; //default

  const { town_image, name, title_variants, description_variants } = data;
  let descriptionPosition;

  switch (name) {
    case "town-underwater-temple":
      descriptionPosition = "right";
      coordinates = { x: "65%", y: "34%" };
      break;
    case "town-moonlake":
      descriptionPosition = "left";
      coordinates = { x: "5%", y: "25%" };
      break;
    case "town-morroc":
      descriptionPosition = "left";
      coordinates = { x: "41%", y: "55%" };
      break;
    case "town-prontera":
      descriptionPosition = "left";
      coordinates = { x: "47%", y: "30%" };
      break;
    case "town-gingerbreadcity":
      descriptionPosition = "left";
      coordinates = { x: "90%", y: "-15%" };
      break;
    case "town-lasagna":
      descriptionPosition = "right";
      coordinates = { x: "3%", y: "29%" };
      break;
  }

  const classes = useStyles({
    coordinates: coordinates,
    index: index,
    townImage: `url(${town_image ? extractImagePathUrl(town_image) : ""})`,
  });

  if (name == "town-comingsoon") {
    return (
      <Typography
        variant="h2"
        className={`text-secondary ${classes.comingsoon}`}
      >
        {getLocaleValue(locale, title_variants).content}
      </Typography>
    );
  } else {
    return (
      <Box
        className={classes.buildingWrapper}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          className={classes.building}
          onMouseEnter={() => setSelectedBuilding(name)}
          onMouseOver={() => setSelectedBuilding(name)}
          onMouseOut={() => setSelectedBuilding("")}
          onClick={() => setSelectedBuilding(name)}
        ></Box>
        <Box
          className={classes.title}
          py={0.2}
          px="10px"
          mt={-2}
          textAlign="center"
        >
          {getLocaleValue(locale, title_variants).content}
        </Box>
        <Box
          py={1.2}
          px={2}
          className={`${classes.description} ${
            selectedBuilding == name ? classes.showBuildingDesc : ""
          } ${descriptionPosition}`}
        >
          {getLocaleValue(locale, description_variants).content}
        </Box>
      </Box>
    );
  }
};

export default MapItemDesktop;
