import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import { Typography } from "@material-ui/core";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";

const useStyles = makeStyles({
  buildingWrapper: {
    position: "absolute",
    zIndex: 2,
    top: props => props.coordinates.y,
    left: props => props.coordinates.x,
    animation: "bouncing-building infinite ease-in-out",
    animationDuration: props => props.index * 0.5 + 1.2 + "s",

    "&.town-gingerbreadcity": {
      "@media screen and (min-width: 800px)": {
        top: "-25%",
        left: "70%",
      },
    },
  },
  building: {
    width: "100px",
    height: "100px",
    backgroundImage: props => props.townImage,
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
  },

  title: {
    backgroundColor: "#F1872C",
    fontWeight: "bold",
    color: "white",
    borderRadius: "20px",
    boxShadow: "2px 2px 5px rgba(0,0,0,0.8)!important",
    fontSize: "70%",
    whiteSpace: "nowrap",
    minWidth: "120px",
  },

  description: {
    position: "absolute",
    color: "white",
    background: "rgba(0, 0, 0, 0.8)",
    borderRadius: "20px",
    boxShadow: "2px 2px 5px rgba(0,0,0,0.8)!important",
    width: "180%",
    zIndex: -1,
    opacity: 0,
    pointerEvents: "none",

    "&.town-gingerbreadcity-desc, &.town-moonlake-desc": {
      width: "250%",
    },

    "&.top": {
      top: "-50%",
    },
    "&.bottom": {
      bottom: "-50%",
    },
    "&.center": {
      bottom: 0,
    },
  },

  showBuildingDesc: {
    transition: "all 0.4s ease-in",
    opacity: 1,

    "&.left.top": {
      left: 0,
      top: 0,
      transform: "translateX(0%)  translateY(-105%)",
    },
    "&.right.top": {
      right: 0,
      top: 0,
      transform: "translateX(0%)  translateY(-105%)",
    },

    "&.left.center": {
      left: 0,
      bottom: 0,
      transform: "translateX(-105%)",
    },
    "&.right.center": {
      right: 0,
      bottom: 0,
      transform: "translateX(105%)",
    },

    "&.left.bottom": {
      left: 0,
      bottom: 0,
      transform: "translateX(0%) translateY(105%)",
    },
    "&.right.bottom": {
      right: 0,
      bottom: 0,
      transform: "translateX(0%) translateY(105%)",
    },
  },

  comingsoon: {
    position: "absolute",
    right: "5%",
    top: "50%",
    zIndex: 1,
    width: "auto",

    "@media screen and (max-width: 1024px)": {
      top: "40%",
      right: "10%",
    },
  },
});

const MapItemTablet = ({
  index,
  locale,
  data,
  selectedBuilding,
  setSelectedBuilding,
}) => {
  let coordinates = { x: "0%", y: "0%" }; //default

  const { town_image, name, title_variants, description_variants } = data;
  let descriptionPositionX, descriptionPositionY;

  switch (name) {
    case "town-underwater-temple":
      descriptionPositionX = "right";
      descriptionPositionY = "bottom";
      coordinates = { x: "65%", y: "34%" };
      break;
    case "town-moonlake":
      descriptionPositionX = "right";
      descriptionPositionY = "center";
      coordinates = { x: "5%", y: "25%" };
      break;
    case "town-morroc":
      descriptionPositionX = "left";
      descriptionPositionY = "center";
      coordinates = { x: "41%", y: "55%" };
      break;
    case "town-prontera":
      descriptionPositionX = "right";
      descriptionPositionY = "top";
      coordinates = { x: "47%", y: "30%" };
      break;
    case "town-gingerbreadcity":
      descriptionPositionX = "left";
      descriptionPositionY = "center";
      coordinates = { x: "67%", y: "-30%" };
      break;
    case "town-lasagna":
      descriptionPositionX = "left";
      descriptionPositionY = "bottom";
      coordinates = { x: "3%", y: "29%" };
      break;
  }

  const classes = useStyles({
    coordinates: coordinates,
    index: index,
    townImage: `url(${town_image ? extractImagePathUrl(town_image) : ""})`,
  });

  if (name == "town-comingsoon") {
    return (
      <Typography
        variant="h3"
        className={`text-secondary ${classes.comingsoon}`}
      >
        {getLocaleValue(locale, title_variants).content}
      </Typography>
    );
  } else {
    return (
      <Box
        className={`${classes.buildingWrapper} ${name}`}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          className={classes.building}
          onMouseEnter={() => setSelectedBuilding(name)}
          onMouseOver={() => setSelectedBuilding(name)}
          onMouseOut={() => setSelectedBuilding("")}
          onClick={() => setSelectedBuilding(name)}
        ></Box>
        <Box
          className={classes.title}
          py={0.2}
          px="5px"
          mt={-1}
          textAlign="center"
        >
          {getLocaleValue(locale, title_variants).content}
        </Box>
        <Box
          py={1.2}
          px={2}
          className={`${classes.description} ${
            selectedBuilding == name ? classes.showBuildingDesc : ""
          } ${descriptionPositionX} ${descriptionPositionY} ${name + "-desc"}`}
        >
          {getLocaleValue(locale, description_variants).content}
        </Box>
      </Box>
    );
  }
};

export default MapItemTablet;
