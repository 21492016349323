import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Parser from "html-react-parser";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import CustomIconButton from "../../../shared-components/buttons";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";
import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  job: {
    position: "relative",
    width: props => props.cardSize.width,
    minHeight: props => props.cardSize.height,
    overflowX: "no-scroll",
  },
  backdropImage: props => ({
    position: "absolute",
    width: props.cardSize.width,
    minHeight: props.cardSize.height,
    top: "20px",
    right: 0,
    backgroundImage: `url(${
      props.selectedDisplayGender === "female"
        ? props.job_detail_female
          ? extractImagePathUrl(props.job_detail_female)
          : ""
        : props.job_detail_male
        ? extractImagePathUrl(props.job_detail_male)
        : ""
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left -100px top 60px",
    backgroundSize: "600px 600px",
  }),
  "@keyframes fadeInTranslateRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(60%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  "@keyframes fadeInTranslateLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(-60%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: props => props.cardSize.width,
    minHeight: props => props.cardSize.height,
    maxHeight: props => props.cardSize.height,
  },
  jobTitle: {
    color: "#FF8923",
  },
  jobOneLiner: {
    color: "#FFFFFF",
    whiteSpace: "nowrap",
  },
  jobDescription: {
    textAlign: "center",
    color: "#FFFFFF",
    zIndex: 999,
    fontSize: "1.2em",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: "20px 15px",
  },
  jobLogo: {
    maxWidth: "60px",
    marginBottom: 0,
  },
  genderSign: {
    backgroundColor: "rgba(255,255,255,0.75)",
    position: "absolute!important",
    top: "20%",
    right: "0px",
    padding: "10px",
    borderRadius: "15px 0px 0px 15px",
    display: "flex",
  },
});

const JobViewPhone = ({ locale, data, isInView }) => {
  const {
    job_title_variants,
    job_one_liner_variants,
    job_description_variants,
    job_base_female,
    job_base_male,
    job_detail_female,
    job_detail_male,
    job_stats_variants,
    job_logo,
    default_display_gender,
  } = data;
  const [selectedDisplayGender, setSelectedDisplayGender] = useState(
    default_display_gender
  );
  const cardSize = { width: "100%", height: "650px" };
  const classes = useStyles({
    cardSize,
    selectedDisplayGender,
    job_detail_female,
    job_detail_male,
  });

  return (
    <Box className={classes.job}>
      <Box
        flex="1"
        display="flex"
        height={cardSize.height}
        flexDirection="column"
        alignItems="center"
      >
        <Box className={classes.backdropImage} />
        <Box className={classes.backdrop} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          zIndex="999"
        >
          <Box container py="30px" display="flex">
            <Box display="flex" alignItems="center" px={0.5}>
              <Image className={classes.jobLogo} imageObject={job_logo} />

              <Box
                display="flex"
                ml={1}
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="h5"
                  className={`${classes.jobTitle} text-shadow`}
                >
                  {getLocaleValue(locale, job_title_variants).content}
                </Typography>
                <Typography variant="h6" className={classes.jobOneLiner}>
                  {getLocaleValue(locale, job_one_liner_variants).content}
                </Typography>
              </Box>
            </Box>
            {job_base_female && job_base_male ? (
              <Box className={classes.genderSign}>
                <CustomIconButton
                  mx={1}
                  type="female"
                  bgColor="#e40d7e"
                  isCircle={true}
                  fontSize="2em"
                  width="40px"
                  height="40px"
                  isInactive={selectedDisplayGender != "female"}
                  onClick={() => setSelectedDisplayGender("female")}
                />
                <CustomIconButton
                  mx={1}
                  type="male"
                  bgColor="#199cd9"
                  fontSize="2em"
                  width="40px"
                  height="40px"
                  isCircle={true}
                  isInactive={selectedDisplayGender != "male"}
                  onClick={() => setSelectedDisplayGender("male")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" className={classes.jobDescription}>
            <Typography style={{ fontWeight: "bold" }}>
              {Parser(
                getLocaleValue(locale, job_description_variants).html_content
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobViewPhone;
