import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import FashionController from "./fashion-controller";
import FashionBg from "../../../../images/game-intro/fashion-section-bg.jpg";
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sectionWrapper: {
    backgroundImage: `url(${FashionBg})`,
    backgroundColor: "#644332",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    position: "relative",
  },
  heading: {
    color: "white",
  },
});

const FashionsViewDesktop = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  data,
  anchorName,
}) => {
  const classes = useStyles();

  const [selectedModel, setSelectedModel] = useState(0);
  let selectedModelData = data[selectedModel];

  return (
    <Box
      id={anchorName}
      pb={{ lg: 10, xl: 15 }}
      pt={{ lg: 30, xl: 50 }}
      className={classes.sectionWrapper}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Container>
        <Grid container>
          <Grid item sm={9}>
            <Box textAlign="center" mb={8} position="relative" zIndex="2">
              <Typography variant="h2" className={classes.heading}>
                {getLocaleValue(locale, titleVariants).content}
              </Typography>
              <Typography variant="h4" className={classes.heading}>
                {getLocaleValue(locale, descriptionVariants).content}
              </Typography>
            </Box>
            <Grid container>
              <Grid item sm={6}>
                <FashionController
                  key={`selectedMale` + selectedModel}
                  data={selectedModelData}
                  type="model"
                  gender="male"
                />
              </Grid>
              <Grid item sm={6}>
                <FashionController
                  key={`selectedFemale` + selectedModel}
                  data={selectedModelData}
                  type="model"
                  gender="female"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3}>
            <Box
              position="relative"
              zIndex="2"
              mt={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {data?.map((n, i) => (
                <FashionController
                  key={i}
                  index={i}
                  data={n}
                  type="title"
                  selected={i == selectedModel ? true : false}
                  setSelectedModel={setSelectedModel}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FashionsViewDesktop;
