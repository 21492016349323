import React from "react";
import { useSelector } from "react-redux";

import MapItemViewDesktop from "./map-item-view-desktop";
import MapItemViewTablet from "./map-item-view-tablet";
import MapItemViewPhone from "./map-item-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const MapItemController = ({
  index,
  data,
  selectedBuilding,
  setSelectedBuilding,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <MapItemViewDesktop
          index={index}
          locale={locale}
          data={data}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}
        />
      }
      TabletComponent={
        <MapItemViewTablet
          index={index}
          locale={locale}
          data={data}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}
        />
      }
      PhoneComponent={
        <MapItemViewPhone index={index} locale={locale} data={data} />
      }
    />
  );
};
export default MapItemController;
