import React from "react";
import { useSelector } from "react-redux";

import MapsViewDesktop from "./maps-view-desktop";
import MapsViewTablet from "./maps-view-tablet";
import MapsViewPhone from "./maps-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";

const MapsController = ({
  data,
  location,
  titleVariants,
  descriptionVariants,
  anchorName,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <MapsViewDesktop
          locale={locale}
          location={location}
          data={data}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          anchorName={anchorName}
        />
      }
      TabletComponent={
        <MapsViewTablet
          locale={locale}
          location={location}
          data={data}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          anchorName={anchorName}
        />
      }
      PhoneComponent={
        <MapsViewPhone
          locale={locale}
          location={location}
          data={data}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          anchorName={anchorName}
        />
      }
    />
  );
};
export default MapsController;
