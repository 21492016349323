import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import FashionTitle from "../../../../images/game-intro/fashion-section-button.png";
import FashionBase from "../../../../images/game-intro/fashion-section-base.png";

import { Typography } from "@material-ui/core";

import CustomIconButton from "../../../shared-components/buttons";
import { extractImagePathUrl } from "../../../shared-components/image/image-url-helper";

const useStyles = makeStyles({
  title: {
    backgroundImage: `url(${FashionTitle})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "white",
    cursor: "pointer",
  },
  base: {
    backgroundImage: `url(${FashionBase})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  model: {
    position: "relative",
    zIndex: 2,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "bottom center",
    backgroundImage: props => props.backgroundImage,
  },
  genderSign: {
    position: "absolute!important",
    bottom: "12%",
    right: "12%",
  },
  flippedTitleWrapper: {
    transform: "rotateY(180deg)",
  },
  flippedTitle: {
    transform: "rotateY(-180deg)",
  },
});

const FashionViewDesktop = ({
  index,
  locale,
  data,
  type,
  gender,
  selected,
  setSelectedModel,
}) => {
  const {
    fashion_title_variants,
    female_fashion_image,
    male_fashion_image,
  } = data;

  const classes = useStyles({
    backgroundImage: `url( ${
      gender == "female"
        ? extractImagePathUrl(female_fashion_image)
        : extractImagePathUrl(male_fashion_image)
    })`,
  });

  switch (type) {
    case "title":
      return (
        <Box
          minWidth="300px"
          className={`${classes.title} ${
            index % 2 == 0 ? classes.flippedTitleWrapper : ""
          }`}
          p={2}
          textAlign="center"
          mb={1}
          onClick={() => setSelectedModel(index)}
        >
          <Typography
            variant="h5"
            className={`${selected ? `text-secondary` : ""} ${
              index % 2 == 0 ? classes.flippedTitle : ""
            }`}
          >
            {getLocaleValue(locale, fashion_title_variants).content}
          </Typography>
        </Box>
      );

    case "model":
      return (
        <Box position="relative">
          <Box
            width="100%"
            paddingBottom="100%"
            mb={-3}
            className={classes.model}
          ></Box>
          <CustomIconButton
            type={gender}
            bgColor="transparent"
            fontColor={gender == "female" ? "#e40d7e" : "#199cd9"}
            fontSize="6em"
            width="auto"
            height="auto"
            className={classes.genderSign}
          />
          <Box width="100%" paddingBottom="25%" className={classes.base}></Box>
        </Box>
      );

    default:
      return (
        <Box>{getLocaleValue(locale, fashion_title_variants).content}</Box>
      );
  }
};

export default FashionViewDesktop;
