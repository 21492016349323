import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import FashionController from "./fashion-controller";
import FashionBg from "../../../../images/game-intro/fashion-section-bg.jpg";
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sectionWrapper: {
    backgroundImage: `url(${FashionBg})`,
    backgroundColor: "#644332",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    position: "relative",
  },
  heading: {
    color: "white",
  },
});

const FashionsViewTablet = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  data,
  anchorName,
}) => {
  const classes = useStyles();

  const [selectedModel, setSelectedModel] = useState(0);
  const [genderModel, setGenderModel] = useState("female");

  let selectedModelData = data[selectedModel];

  return (
    <Box
      id={anchorName}
      pb={{ xs: 5 }}
      pt={{ xs: 18 }}
      className={classes.sectionWrapper}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Container>
        <Box textAlign="center" mb={6} position="relative" zIndex="2">
          <Typography variant="h3" className={classes.heading}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Typography variant="h5" className={classes.heading}>
            {getLocaleValue(locale, descriptionVariants).content}
          </Typography>
        </Box>
        <Grid container alignItems="center" justify="center">
          <Grid item sm={8} md={6}>
            <FashionController
              key={`selectedModel` + selectedModel}
              data={selectedModelData}
              type="model"
              gender={genderModel}
              setGenderModel={setGenderModel}
            />
          </Grid>
          <Grid item sm={4} md={3}>
            <Box
              position="relative"
              zIndex="2"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {data?.map((n, i) => (
                <FashionController
                  key={i}
                  index={i}
                  data={n}
                  type="title"
                  selected={i == selectedModel ? true : false}
                  setSelectedModel={setSelectedModel}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FashionsViewTablet;
