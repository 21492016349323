import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";
import JobViewDesktop from "./job-view-desktop";

import CustomIconButton from "../../../shared-components/buttons";
import StarryBg from "../../../shared-components/starry-bg";
import TrailingArrows from "../../../shared-components/trailing-arrows";
import Flickity from "react-flickity-component";

// Cloud images
import clouds from "../../../../images/game-intro/jobs-section-clouds.png";
import cloud1 from "../../../../images/game-intro/cloud-1.png";
import cloud2 from "../../../../images/game-intro/cloud-2.png";
import cloud3 from "../../../../images/game-intro/cloud-3.png";
import cloud4 from "../../../../images/game-intro/cloud-4.png";

import HintBox from "../../../shared-components/hint-box";

const useStyles = makeStyles({
  sectionJobs: {
    background: "linear-gradient(0deg, #1D70B7, #5CC0E1, #152D60)",
    position: "relative",
    zIndex: 1,

    "&::after": {
      content: '""',
      backgroundImage: `url(${clouds})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "bottom center",
      display: "block",
      width: "100%",
      paddingBottom: "60%",
      position: "absolute",
      bottom: "-10%",
      zIndex: 0,
      animation: "bouncing-clouds 4s infinite ease-in-out",

      "@media screen and (max-width: 1600px)": {
        backgroundSize: "cover",
      },
    },
  },
  cloud: {
    width: "30%",
    paddingBottom: "25%",
    bottom: 0,
    position: "absolute",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    animation: "bouncing-clouds infinite ease-in-out",
    zIndex: 1,
  },
  cloud1: {
    backgroundImage: `url(${cloud1})`,
    left: "50%",
    bottom: "-10%",
    animationDuration: "2s",
  },
  cloud2: {
    backgroundImage: `url(${cloud2})`,
    left: "10%",
    bottom: "-8%",
    animationDelay: "1s",
    animationDuration: "3.5s",
  },
  cloud3: {
    backgroundImage: `url(${cloud3})`,
    right: "0",
    animationDuration: "2.2s",
  },
  cloud4: {
    backgroundImage: `url(${cloud4})`,
    left: "-10%",
    bottom: "-5%",
    animationDuration: "2.5s",
  },

  scene: {
    width: "100%",
    margin: "10px auto",
    zIndex: 3,
    padding: "0 10%",
    // backgroundColor: "red",
  },
  flickityOverwrite: {
    width: "100%",
    "& .flickity-prev-next-button.next": {
      right: "30% !important",
    },
    "& .flickity-prev-next-button.previous": {
      left: "30% !important",
    },
    "& .flickity-slider": {
      width: "100%",
    },
    "& .flickity-viewport": {
      minHeight: "30vw",
      overflow: "visible",
      overflowX: "hidden !important",
    },
  },
  title: {
    color: "#ffffff",
  },
  jobTitle: {
    textTransform: "uppercase",
  },
  jobOneLiner: {
    color: "#70706F",
  },
  starrybg: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  hintBoxWrapper: {
    position: "absolute",
    zIndex: 5,
    bottom: 150,
  },
});

const JobsViewDesktop = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  instructionVariants,
  data,
  anchorName,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isViewedDetail, setIsViewedDetail] = useState(false);
  const [selectedJob, setSelectedJob] = useState(data ? data[1] : null);

  useEffect(() => {
    let currentDataIndex = selectedIndex % data.length;
    if (currentDataIndex < 0) {
      currentDataIndex += data.length;
    } else if (currentDataIndex >= data.length) {
      currentDataIndex -= data.length;
    }
    setSelectedJob(data[currentDataIndex]);
  }, [selectedIndex]);

  const classes = useStyles();

  // Can check the data object structure example at the bottom
  return (
    <Box
      id={anchorName}
      component="section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.sectionJobs}
      pt={5}
      pb={5}
    >
      <Box className={classes.starrybg}>
        <StarryBg />
      </Box>
      <Box className={`${classes.cloud} ${classes.cloud1}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud2}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud3}`}></Box>
      <Box className={`${classes.cloud} ${classes.cloud4}`}></Box>

      <Container maxWidth="lg">
        <Box textAlign="center" mb={5}>
          <Typography variant="h2" className={classes.title}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Box textAlign="center">
            <Typography variant="h4" className={classes.title}>
              {getLocaleValue(locale, descriptionVariants).content}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box
        className={classes.scene}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Flickity
          flickityRef={c => {
            c.on("change", function () {
              setSelectedIndex(c.selectedIndex);
              setSelectedJob(data[c.selectedIndex]);
            });
          }}
          className={`gallery ${classes.flickityOverwrite}`}
          elementType={"div"} // default 'div'
          options={{
            wrapAround: true,
            pageDots: false,
            initialIndex: selectedIndex,
            prevNextButtons: true,
          }} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate={false} // default false
          static // default false
        >
          {data &&
            data.map((n, i) => (
              <JobViewDesktop
                index={i}
                key={i}
                selectedIndex={selectedIndex}
                totalCount={data.length}
                locale={locale}
                data={n}
                setIsViewedDetail={setIsViewedDetail}
              />
            ))}
        </Flickity>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={-10}
        className={classes.hintBoxWrapper}
      >
        <HintBox
          mt={2}
          text={getLocaleValue(locale, instructionVariants).content}
          fontColor="white"
          isVisible={!isViewedDetail}
          trailingPosition="top"
          trailingArrowsDirection="top"
        ></HintBox>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx="50px"
          width="500px"
          zIndex={1}
          position="relative"
        >
          <Typography
            variant="h4"
            className={`text-secondary ${classes.jobTitle}`}
          >
            {selectedJob &&
              getLocaleValue(locale, selectedJob.job_title_variants).content}
          </Typography>
          <Typography variant="h5" className={classes.jobOneLiner}>
            {selectedJob &&
              getLocaleValue(locale, selectedJob.job_one_liner_variants)
                .content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default JobsViewDesktop;
