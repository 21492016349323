import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import { Typography } from "@material-ui/core";

import Img from "gatsby-image";
import Image from "../../../shared-components/image";

const useStyles = makeStyles({
  buildingImg: {
    width: "100%",
    marginBottom: "15px",
    borderRadius: "20px",
    boxShadow: "3px 3px 5px 2px rgba(0,0,0,0.5)!important",
  },
  desc: {
    fontSize: "1.2em",
  },
});

const MapItemTablet = ({ index, locale, data }) => {
  const {
    town_mobile_image,
    name,
    title_variants,
    description_variants,
  } = data;

  const classes = useStyles();

  return (
    <Box className="gallery-cell-full" p={1.5}>
      <Image
        loading="eager"
        imageObject={town_mobile_image}
        className={classes.buildingImg}
      />
      <Typography variant="h5" className={`text-secondary`}>
        {getLocaleValue(locale, title_variants).content}
      </Typography>
      <Typography variant="h6" className={classes.desc}>
        {getLocaleValue(locale, description_variants).content}
      </Typography>
    </Box>
  );
};

export default MapItemTablet;
