import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import FashionController from "./fashion-controller";
import FashionBg from "../../../../images/game-intro/fashion-section-bg.jpg";
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sectionWrapper: {
    backgroundImage: `url(${FashionBg})`,
    backgroundColor: "#644332",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    position: "relative",
  },
  heading: {
    color: "white",
  },
});

const FashionsViewPhone = ({
  locale,
  location,
  titleVariants,
  descriptionVariants,
  data,
  anchorName,
}) => {
  const classes = useStyles();

  const [selectedModel, setSelectedModel] = useState(0);
  const [genderModel, setGenderModel] = useState("female");

  let selectedModelData = data[selectedModel];

  return (
    <Box
      id={anchorName}
      pb={{ xs: 8 }}
      pt={{ xs: 10 }}
      className={classes.sectionWrapper}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Container>
        <Box textAlign="center" mb={3} px={1} position="relative" zIndex="2">
          <Typography variant="h4" className={classes.heading}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Typography variant="h6" className={classes.heading}>
            {getLocaleValue(locale, descriptionVariants).content}
          </Typography>
        </Box>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <FashionController
              key={`selectedModel` + selectedModel}
              data={selectedModelData}
              type="model"
              gender={genderModel}
              setGenderModel={setGenderModel}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center">
              {data?.map((n, i) => (
                <Grid item xs={6} key={i}>
                  <FashionController
                    index={i}
                    data={n}
                    type="title"
                    selected={i == selectedModel ? true : false}
                    setSelectedModel={setSelectedModel}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FashionsViewPhone;
