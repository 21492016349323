import { extractImagePathUrl } from "./image-url-helper";

export const setImageWhenOnload = (imageObj, setImage) => {
  var imageUrl = imageObj.url ? extractImagePathUrl(imageObj) : null;

  if (imageUrl) {
    const imgBefore = new Image();
    imgBefore.src = imageUrl;
    if (setImage) {
      imgBefore.onload = () => {
        setImage(imageUrl);
      };
    }
  }
};
