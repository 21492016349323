import React from "react";

import { Box } from "@material-ui/core";
import CustomIconButton from "../buttons";
import { makeStyles } from "@material-ui/core/styles";

/**
 * Custom Icon Button Component
 *
 * @param {int} offsetToShow - the offset from top before showing button, 100 by default
 * @param {string} position - the position of button (bottom-left, bottom-right), bottom-right by default
 * @param {string} type - the type of button, directional (top, right, bottom, left) or gender (female/male)
 * @param {string} width - width of button, can be given in terms of string "40px" or number, 40px by default
 * @param {string} height - height of button, can be given in terms of string "40px" or number, 40px by default
 * @param {number} fontSize - font size of icon within the button, 2em by default
 * @param {string} bgColor - the background color of button, #f0862c by default
 * @param {string} fontColor - the text/icon color of button, #fff by default
 * @param {function} onClick - callback function when on click
 * @param {boolean} isDisabled - to determine if button is disabled, false by default, button turns grey and no click callbacks will be executed
 * @param {boolean} isInactive - to determine if button is inactive/unselected and opacity will drop, false by default
 * @param {boolean} isCircle - to determine if button is circular, true by default
 * @param {number} py - Vertical padding values of button, 0 by default
 * @param {number} px - Horizontal padding values of button, 0 by default
 * @param {number} my - Vertical margin values of button, 0 by default
 * @param {number} mx - Horizontal margin values of button, 0 by default
 */

const useStyles = makeStyles({
  backToTop: {
    position: "fixed",
    zIndex: 10,
  },
  bottomLeft: {
    left: 20,
    bottom: 30,
  },
  bottomRight: {
    right: 20,
    bottom: 30,
  },
});

const BackToTop = ({
  offsetToShow,
  position,
  type,
  width,
  height,
  fontSize,
  bgColor,
  fontColor,
  onClick,
  isDisabled,
  isInactive,
  isCircle,
  py,
  px,
  my,
  mx,
}) => {
  const classes = useStyles();

  if (typeof window !== "undefined") {
    // browser code
    window.addEventListener("scroll", () => {
      var scrollTop = document.documentElement.scrollTop;
      var backToTop = document.getElementById("backToTopButton");

      if (backToTop) {
        if (scrollTop > offsetToShow) {
          //show button
          backToTop.classList.add("fadeIn");
        } else {
          //hide button
          backToTop.classList.remove("fadeIn");
        }
      }
    });
  }

  return (
    <Box
      id="backToTopButton"
      className={`fade ${classes.backToTop} ${
        position == "bottom-left"
          ? classes.bottomLeft
          : position == "bottom-right"
          ? classes.bottomRight
          : classes.bottomRight
      }`}
      onClick={() => {
        document.documentElement.scrollTop = 0;
      }}
    >
      <CustomIconButton
        type={type}
        width={width}
        height={height}
        fontSize={fontSize}
        bgColor={bgColor}
        fontColor={fontColor}
        onClick={onClick}
        isDisabled={isDisabled}
        isInactive={isInactive}
        isCircle={isCircle}
        py={py}
        px={px}
        my={my}
        mx={mx}
      />
    </Box>
  );
};

BackToTop.defaultProps = {
  offsetToShow: 100,
  position: "bottom-right",
  type: "top",
  width: "50px",
  height: "50px",
  isDisabled: false,
  isInactive: false,
  isCircle: true,
  fontSize: "2.2em",
  bgColor: "#f0862c",
  fontColor: "#fff",
  py: 0,
  px: 0,
  my: 0,
  mx: 0,
};

export default BackToTop;
