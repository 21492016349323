import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getLocaleValue } from "../../../../helpers/get-locale-value";

import cloudsBg from "../../../../images/game-intro/maps-section-cloud-bg.png";
import cloud from "../../../../images/game-intro/cloud-2.png";

import MapItemController from "./map-item-controller";

import Flickity from "react-flickity-component";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sectionWrapper: {
    background: "linear-gradient(0deg, #fafafa, #1D70B7)",
    position: "relative",
  },
  cloudsBgWrapper: {
    backgroundImage: `url(${cloudsBg})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    animation: "bouncing-clouds-2 3s infinite ease-in-out",
    marginTop: "20%",
    width: "200%",
    zIndex: 0,
  },
  heading: {
    color: "#fff",
  },
  headingWrapper: {
    position: "relative",
    zIndex: 1,
  },
  cloud: {
    width: "100%",
    paddingBottom: "100%",
    position: "absolute",
    right: 0,
    bottom: "-15%",
    backgroundImage: `url(${cloud})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    animation: "bouncing-clouds-2 2s infinite ease-in-out",
    opacity: 0.7,
  },
});

const MapsViewTablet = ({
  locale,
  location,
  data,
  titleVariants,
  descriptionVariants,
  anchorName,
}) => {
  const classes = useStyles();

  return (
    <Box
      id={anchorName}
      pt={10}
      className={classes.sectionWrapper}
      width="100%"
    >
      <Box
        position="absolute"
        width="100%"
        pb="100%"
        className={classes.cloudsBgWrapper}
      ></Box>
      <Box className={classes.cloud}></Box>

      <Container maxWidth="lg" className={classes.headingWrapper}>
        <Box textAlign="center">
          <Typography variant="h4" className={classes.heading}>
            {getLocaleValue(locale, titleVariants).content}
          </Typography>
          <Typography variant="h6" className={classes.heading}>
            {getLocaleValue(locale, descriptionVariants).content}
          </Typography>
        </Box>
      </Container>

      <Box width="100%" px={2} mt={5} mb={2}>
        <Flickity
          className={`carousel gallery`} // default ''
          elementType={"div"} // default 'div'
          options={{
            wrapAround: false,
            pageDots: false,
            initialIndex: 0,
            prevNextButtons: true,
            contain: true,
          }} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {data &&
            data.map(
              (n, i) =>
                n.towns &&
                n.towns.map((m, j) => (
                  <MapItemController key={j} index={j} data={m} />
                ))
            )}
        </Flickity>
      </Box>
    </Box>
  );
};

export default MapsViewTablet;
