import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import PetsViewDesktop from "./pets-view-desktop";
import PetsViewTablet from "./pets-view-tablet";
import PetsViewPhone from "./pets-view-phone";

import ResponsiveWrapper from "../../../shared-components/responsive-wrapper";
import { setImageWhenOnload } from "../../../shared-components/image/image-onload";

const PetsController = ({
  titleVariants,
  instructionVariants,
  descriptionVariants,
  data,
  location,
  anchorName,
}) => {
  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  useEffect(() => {
    for (const item of data) {
      setImageWhenOnload(item.egg_image.before.formats.thumbnail, null);
      setImageWhenOnload(item.egg_image.before, null);
      setImageWhenOnload(item.egg_image.hatching, null);
    }
  }, []);

  return (
    <ResponsiveWrapper
      DesktopComponent={
        <PetsViewDesktop
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      TabletComponent={
        <PetsViewTablet
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
      PhoneComponent={
        <PetsViewPhone
          locale={locale}
          location={location}
          titleVariants={titleVariants}
          descriptionVariants={descriptionVariants}
          instructionVariants={instructionVariants}
          data={data}
          anchorName={anchorName}
        />
      }
    />
  );
};
export default PetsController;
